body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*This is a hack, ideally would be in style local to components*/
.highlightLight {
  background-color: rgba(150,160, 250, 0.1);
}
.highlightDark {
  background-color: rgba(150,160, 250, 0.1);
}
.selectedLight {
  background-color: rgba(255, 0, 0, 0.28);
}
.selectedDark {
  background-color: rgba(255, 0, 0, 0.28);
}

/*Override ant design 5 top margin for Title*/
h3, h4, h5 {
  margin-top: 0;
}

